/////////////////////////////////////////////////////////
// Configurations related to listing.                  //
// Main configuration here is the extended data config //
/////////////////////////////////////////////////////////
import doctor1 from '../assets/doctorImages/Doctors Menopause.pdf-image-000.jpg';
import doctor2 from '../assets/doctorImages/Doctors Menopause.pdf-image-001.jpg';
import doctor3 from '../assets/doctorImages/Doctors Menopause.pdf-image-002.jpg';
import doctor4 from '../assets/doctorImages/Doctors Menopause.pdf-image-003.jpg';
import doctor5 from '../assets/doctorImages/Doctors Menopause.pdf-image-004.jpg';
import doctor6 from '../assets/doctorImages/Doctors Menopause.pdf-image-005.jpg';
import doctor7 from '../assets/doctorImages/Doctors Menopause.pdf-image-006.jpg';
import doctor8 from '../assets/doctorImages/Doctors Menopause.pdf-image-007.jpg';
import doctor9 from '../assets/doctorImages/Doctors Menopause.pdf-image-008.jpg';
import doctor10 from '../assets/doctorImages/Doctors Menopause.pdf-image-009.jpg';
import doctor11 from '../assets/doctorImages/Doctors Menopause.pdf-image-010.jpg';

import doctor12 from '../assets/doctorImages/Doctors Menopause.pdf-image-012.jpg';
import doctor13 from '../assets/doctorImages/Doctors Menopause.pdf-image-013.jpg';
import doctor14 from '../assets/doctorImages/Doctors Menopause.pdf-image-014.png';
import doctor16 from '../assets/doctorImages/Doctors Menopause.pdf-image-016.jpg';
import doctor17 from '../assets/doctorImages/Doctors Menopause.pdf-image-017.jpg';
import doctor18 from '../assets/doctorImages/Doctors Menopause.pdf-image-019.jpg';
import doctor19 from '../assets/doctorImages/Doctors Menopause.pdf-image-020.jpg';
import doctor20 from '../assets/doctorImages/Doctors Menopause.pdf-image-021.jpg';
import doctor21 from '../assets/doctorImages/Doctors Menopause.pdf-image-022.jpg';

import { renameObjKeys } from '../util/dataExtractors';
import { EXPERTISE_OPTIONS, PATIENT_OPTIONS } from './customConfig';

// NOTE: if you want to change the structure of the data,
// you should also check src/util/configHelpers.js
// some validation is added there.

/**
 * Configuration options for listing fields (custom extended data fields):
 * - key:                           Unique key for the extended data field.
 * - scope (optional):              Scope of the extended data can be either 'public' or 'private'.
 *                                  Default value: 'public'.
 *                                  Note: listing doesn't support 'protected' scope atm.
 * - includeForListingTypes:        An array of listing types, for which the extended
 *   (optional)                     data is relevant and should be added.
 * - schemaType (optional):         Schema for this extended data field.
 *                                  This is relevant when rendering components and querying listings.
 *                                  Possible values: 'enum', 'multi-enum', 'text', 'long', 'boolean'.
 * - enumOptions (optional):        Options shown for 'enum' and 'multi-enum' extended data.
 *                                  These are used to render options for inputs and filters on
 *                                  EditListingPage, ListingPage, and SearchPage.
 * - filterConfig:                  Filter configuration for listings query.
 *    - indexForSearch (optional):    If set as true, it is assumed that the extended data key has
 *                                    search index in place. I.e. the key can be used to filter
 *                                    listing queries (then scope needs to be 'public').
 *                                    Note: Flex CLI can be used to set search index for the key:
 *                                    https://www.sharetribe.com/docs/references/extended-data/#search-schema
 *                                    Read more about filtering listings with public data keys from API Reference:
 *                                    https://www.sharetribe.com/api-reference/marketplace.html#extended-data-filtering
 *                                    Default value: false,
 *   - filterType:                    Sometimes a single schemaType can be rendered with different filter components.
 *                                    For 'enum' schema, filterType can be 'SelectSingleFilter' or 'SelectMultipleFilter'
 *   - label:                         Label for the filter, if the field can be used as query filter
 *   - searchMode (optional):         Search mode for indexed data with multi-enum schema.
 *                                    Possible values: 'has_all' or 'has_any'.
 *   - group:                         SearchPageWithMap has grouped filters. Possible values: 'primary' or 'secondary'.
 * - showConfig:                    Configuration for rendering listing. (How the field should be shown.)
 *   - label:                         Label for the saved data.
 *   - isDetail                       Can be used to hide detail row (of type enum, boolean, or long) from listing page.
 *                                    Default value: true,
 * - saveConfig:                    Configuration for adding and modifying extended data fields.
 *   - label:                         Label for the input field.
 *   - placeholderMessage (optional): Default message for user input.
 *   - isRequired (optional):         Is the field required for providers to fill
 *   - requiredMessage (optional):    Message for those fields, which are mandatory.
 */

export const listingFields = [
  // {
  //   key: 'speciality',
  //   scope: 'public',
  //   schemaType: 'enum',
  //   enumOptions: PATIENT_OPTIONS.map(o => renameObjKeys(o, { value: 'option' })),
  //   filterConfig: {
  //     indexForSearch: true,
  //     filterType: 'SelectMultipleFilter',
  //     label: 'Speciality',
  //     group: 'primary',
  //   },
  //   showConfig: {
  //     label: 'Speciality',
  //     isDetail: true,
  //   },
  //   saveConfig: {
  //     label: 'Speciality',
  //     placeholderMessage: 'Select an option…',
  //     isRequired: true,
  //     requiredMessage: 'You need to select a speciality.',
  //   },
  // },
  // {
  //   key: 'expertise',
  //   scope: 'public',
  //   schemaType: 'multi-enum',
  //   enumOptions: EXPERTISE_OPTIONS.map(o => renameObjKeys(o, { value: 'option' })),
  //   filterConfig: {
  //     indexForSearch: true,
  //     filterType: 'SelectMultipleFilter',
  //     label: 'Expertise',
  //     group: 'primary',
  //   },
  //   showConfig: {
  //     label: 'Expertise',
  //     isDetail: true,
  //   },
  //   saveConfig: {
  //     label: 'Expertise',
  //     placeholderMessage: 'Select an option…',
  //     isRequired: true,
  //     requiredMessage: 'You need to select a expertise.',
  //   },
  // },
  // {
  //   key: 'tire',
  //   scope: 'public',
  //   schemaType: 'enum',
  //   enumOptions: [
  //     { option: '29', label: '29' },
  //     { option: '28', label: '28' },
  //     { option: '27', label: '27' },
  //     { option: '26', label: '26' },
  //     { option: '24', label: '24' },
  //     { option: '20', label: '20' },
  //     { option: '18', label: '18' },
  //   ],
  //   filterConfig: {
  //     indexForSearch: true,
  //     label: 'Tire size',
  //     group: 'secondary',
  //   },
  //   showConfig: {
  //     label: 'Tire size',
  //     isDetail: true,
  //   },
  //   saveConfig: {
  //     label: 'Tire size',
  //     placeholderMessage: 'Select an option…',
  //     isRequired: true,
  //     requiredMessage: 'You need to select a tire size.',
  //   },
  // },
  // {
  //   key: 'brand',
  //   scope: 'public',
  //   schemaType: 'enum',
  //   enumOptions: [
  //     { option: 'cube', label: 'Cube' },
  //     { option: 'diamant', label: 'Diamant' },
  //     { option: 'ghost', label: 'GHOST' },
  //     { option: 'giant', label: 'Giant' },
  //     { option: 'kalkhoff', label: 'Kalkhoff' },
  //     { option: 'kona', label: 'Kona' },
  //     { option: 'otler', label: 'Otler' },
  //     { option: 'vermont', label: 'Vermont' },
  //   ],
  //   filterConfig: {
  //     indexForSearch: true,
  //     label: 'Brand',
  //     group: 'secondary',
  //   },
  //   showConfig: {
  //     label: 'Brand',
  //     isDetail: true,
  //   },
  //   saveConfig: {
  //     label: 'Brand',
  //     placeholderMessage: 'Select an option…',
  //     isRequired: true,
  //     requiredMessage: 'You need to select a brand.',
  //   },
  // },
  // {
  //   key: 'accessories',
  //   scope: 'public',
  //   schemaType: 'multi-enum',
  //   enumOptions: [
  //     { option: 'bell', label: 'Bell' },
  //     { option: 'lights', label: 'Lights' },
  //     { option: 'lock', label: 'Lock' },
  //     { option: 'mudguard', label: 'Mudguard' },
  //   ],
  //   filterConfig: {
  //     indexForSearch: true,
  //     label: 'Accessories',
  //     searchMode: 'has_all',
  //     group: 'secondary',
  //   },
  //   showConfig: {
  //     label: 'Accessories',
  //   },
  //   saveConfig: {
  //     label: 'Accessories',
  //     placeholderMessage: 'Select an option…',
  //     isRequired: false,
  //   },
  // },
  // // An example of how to use transaction type specific custom fields and private data.
  // {
  //   key: 'note',
  //   scope: 'public',
  //   includeForListingTypes: ['product-selling'],
  //   schemaType: 'text',
  //   showConfig: {
  //     label: 'Extra notes',
  //   },
  //   saveConfig: {
  //     label: 'Extra notes',
  //     placeholderMessage: 'Some public extra note about this bike...',
  //   },
  // },
  // {
  //   key: 'privatenote',
  //   scope: 'private',
  //   includeForListingTypes: ['daily-booking'],
  //   schemaType: 'text',
  //   saveConfig: {
  //     label: 'Private notes',
  //     placeholderMessage: 'Some private note about this bike...',
  //   },
  // },
];

///////////////////////////////////////////////////////////////////////
// Configurations related to listing types and transaction processes //
///////////////////////////////////////////////////////////////////////

// A presets of supported listing configurations
//
// Note 1: With first iteration of hosted configs, we are unlikely to support
//         multiple listing types, even though this template has some
//         rudimentary support for it.
// Note 2: transaction type is part of listing type. It defines what transaction process and units
//         are used when transaction is created against a specific listing.

/**
 * Configuration options for listing experience:
 * - listingType:     Unique string. This will be saved to listing's public data on
 *                    EditListingWizard.
 * - label            Label for the listing type. Used as microcopy for options to select
 *                    listing type in EditListingWizard.
 * - transactionType  Set of configurations how this listing type will behave when transaction is
 *                    created.
 *   - process          Transaction process.
 *                      The process must match one of the processes that this client app can handle
 *                      (check src/util/transaction.js) and the process must also exists in correct
 *                      marketplace environment.
 *   - alias            Valid alias for the aforementioned process. This will be saved to listing's
 *                      public data as transctionProcessAlias and transaction is initiated with this.
 *   - unitType         Unit type is mainly used as pricing unit. This will be saved to
 *                      transaction's protected data.
 *                      Recommendation: don't use same unit types in completely different processes
 *                      ('item' sold should not be priced the same as 'item' booked).
 * - stockType        This is relevant only to listings with product-selling listing type.
 *                    If set to 'oneItem', stock management is not showed and the listing is
 *                    considered unique (stock = 1).
 *                    Possible values: 'oneItem' and 'multipleItems'.
 *                    Default: 'multipleItems'.
 */


export const englishDoctorDetails =
[
  {
    "name": "Sabine Wünschmann",
    "title": "Surgeon Gynecologist - Obstetrician, Specializing in Hormonal Restoration",
    "description": "She graduated in 1990 from the Medical School of the University of Frankfurt. During her studies, she practiced in Hamburg, Paris, Australia, Switzerland, China, and Taiwan. In the period 1990-1997 she got her specialization in Obstetrics and Gynecology from the Frankfurt hospital.",
    "image": doctor1
  },
  {
    "name": "Paraskevi Mendzelopoulou",
    "title": "Endocrinologist-Diabetologist",
    "description": "Paraskevi Mendzelopoulou graduated from the School of Medicine of the Aristotle University of Thessaloniki (1992-1998) and then completed her term as a rural doctor at the General Hospital of Aigio (1998-2000). In 2001 she left for the USA to continue her education where she received her specialization in Pathology at Yale University Internal Medicine Program.",
    "image": doctor2
  },
  {
    "name": "Maria Haramanda",
    "title": "Obstetrician-Gynecologist",
    "description": "Maria Haramanda is a Gynecologist - Obstetrician, graduate of the School of Medicine of the Aristotle University of Thessaloniki and holds a master's degree in Reproductive and Regenerative Medicine from the National and Kapodistrian University of Athens.",
    "image": doctor3
  },
  {
    "name": "Anna Gavrieli",
    "title": "Dietitian-Nutritionist",
    "description": "Anna Gavrieli is a Dietitian-Nutritionist. She graduated from the Department of Nutrition and Dietetics at the Harokopio University in Athens. She then completed her Masters in Sports Nutrition at Loughborough University in the UK.",
    "image": doctor4
  },
  {
    "name": "Iliana Karagouni",
    "title": "Clinical Dietitian",
    "description": "Iliana Karagouni is a graduate of the Nursing department of the National and Kapodistrian University of Athens and holds a postgraduate diploma specializing in 'Organization and Management of Health Services'. She also holds a diploma from the Department of Dietetics-Nutrition of Harokopio University.",
    "image": doctor5
  },
  {
    "name": "Alina Chaturova",
    "title": "Psychologist",
    "description": "Alina Chaturova is a Psychologist. She specialized in Clinical Hypnotherapy and Cognitive Counseling, as well as Neurolinguistic Programming. From 2018 to 2021, she studied at the University of East London. In 2021-2022 she obtained a Professional Diploma in Hypnotherapy, while in 2022-2023 she completed the Advanced Professional Diploma in Hypnotherapy with Psychotherapy at Soleas Academy.",
    "image": doctor6
  },
  {
    "name": "Fotini Ferenidou",
    "title": "Psychiatrist and Doctor of Medicine of the Aristotle University",
    "description": "Fotini Ferenidou, MD, MSc, FECSM, is a Psychiatrist and Doctor of Medicine at the Aristotle University of Thessaloniki. She graduated from the School of Medicine of the Aristotle University of Thessaloniki and continued her studies with the Postgraduate Diploma of Specialization in 'Medical Research Methodology-Clinical Research' (MSc) of the School of Medicine of the University of Athens'.",
    "image": doctor7
  },
  {
    "name": "Eirini Minopoulou",
    "title": "Clinical Psychologist - Psychotherapist, MSc",
    "description": "Eirini Minopoulou is a clinical psychologist and psychotherapist, MSc, specializing in couple and family therapy and clinical sexology. She works as a scientific associate at the Special Sexual Health & Gender Discrepancy Clinic at Aiginiteio Hospital and the A' Psychiatric Clinic of the University of Athens. She is also a member of the Hellenic Society of Systemic Thinking and Family Therapy.",
    "image": doctor8
  },
  {
    "name": "Heraklis Mourikis",
    "title": "Psychiatrist",
    "description": "Heraklis Mourikis is a Psychiatrist, Psychotherapist, with clinical and research work at the 1st Psychiatric Clinic of the University of Athens, at the Aeginite Hospital. He is the head of the Behavioral Therapy Unit, the Obsessive-Compulsive Disorder Specialist Clinic and the Sexual Health Specialist Clinic.",
    "image": doctor9
  },
  {
    "name": "Vivi Sotiropoulou",
    "title": "Psychologist, Clinical Sexologist",
    "description": "Vivi Sotiropoulou is a psychologist, PhD in psychology (Panteion University), specialized in the treatment of anxiety and sexual disorders. She is a scientific associate of the First Psychiatric Clinic of the University of Athens, Aeginetio Hospital.",
    "image": doctor10
  },
  {
    "name": "Maria Basta",
    "title": "Psychiatrist, Associate Professor of Psychiatry",
    "description": "The course of Dr. Maria Basta in the field of psychiatry began when she graduated from the Medical School of the University of Crete in 1997. Her passion for psychiatry led her to complete her residency at the Department of Psychiatry of the University Hospital of Heraklion in 2006.",
    "image": doctor11
  },
  {
    "name": "Mandy Mouzakiti",
    "title": "Psychologist",
    "description": "Mandy Mouzakiti studied Psychology at the University of Crete. She has been trained in cognitive-behavioral psychotherapy at the Society for Cognitive Behavioral Studies according to the criteria of the European Association for Behavioral and Cognitive Therapies (EABCT).",
    "image": doctor12
  },
  {
    "name": "Sofia Katoudis",
    "title": "Psychologist-Psychotherapist",
    "description": "Sofia Katoudis is a psychologist, graduate of the Psychology department of Panteion University. She is trained in Cognitive Behavioral Therapy (CBT) at the Society for Cognitive Behavioral Studies and has completed a two-year training program in Mindfulness-Based Cognitive Therapy (MBCT) organized by the Oxford Mindfulness Foundation in collaboration with the EGSS",
    "image": doctor13
  },
  {
    "name": "Noni Melina",
    "title": "Psychologist",
    "description": "Noni Melina is a Psychologist - Psychotherapist and maintains a private office in Maroussi. She specialized in Cognitive Behavioral Therapy for children-adolescents and adults at the Behavioral Research and Therapy Institute.",
    "image": doctor14
  },
  {
    "name": "Fotini Lekka",
    "title": "Psychologist",
    "description": "Fotini Lekka is a Psychologist, she has specialized in Clinical Psychology at postgraduate level and has a PhD in Clinical Psychology. She is a Teacher and Supervisor of Cognitive-Behavioral Psychotherapy at the Society for Cognitive-Behavioral Studies.",
    "image": doctor16
  },
  {
    "name": "Yannis Zervas",
    "title": "Professor of Psychiatry, School of Medicine, EKPA",
    "description": "Yannis Zervas is a Professor of Psychiatry at the School of Medicine of the National and Kapodistrian University of Athens. He received his MD and PhD from the University of Athens and was trained in Psychiatry and Counseling Interfacing Psychiatry and Psychosomatic Medicine and Clinical Research at Stony Brook University Hospital, SUNY Medical School in New York, USA.",
    "image": doctor17
  },
  {
    "name": "Vicky Kouta",
    "title": "Psychologist",
    "description": "Vicky Kouta is a Psychologist-Psychotherapist of Cognitive Behavioral Approach (CBT). She is a graduate of the Department of Philosophy, Pedagogy and Psychology of the National and Kapodistrian University of Athens. She then lived for 3 years in the city of Edinburgh, where she completed her postgraduate studies in Child and Adolescent Mental Health, at the University of Edinburgh.",
    "image": doctor18
  },
  {
    "name": "Chrysa Vafiadi",
    "title": "Physiotherapist, Msc in Manual Therapy and Women's Health",
    "description": "Chrysa Vafiadi has a Bsc in Physiotherapy, Msc in Manual Therapy and Women's Health. After completing her degree in physiotherapy, she attended the University of Sheffield and Bradford to complete her double Masters in Manual Therapy and Physiotherapy in the UK. She specializes in women's health physiotherapy and MSK (Musculoskeletal) physiotherapy.",
    "image": doctor19
  },
  {
    "name": "Nikos Lagos",
    "title": "Physiotherapist",
    "description": "Born in Athens, he got involved in sports from an early age and continued with championships in youth and men's athletics. He was distinguished several times as Panhellenic triple jump champion in all categories of the sport, as well as in World and Pan-European championships. He studied physical therapy at the University of East London",
    "image": doctor20
  },
  {
    "name": "Giorgos Kitsantas",
    "title": "Strength Coach-Physiotherapist",
    "description": "Giorgos Kitsantas is a Physiotherapist and Strength Coach. After his professional involvement in volleyball, he began his training at a private fitness school, obtaining the diplomas of personal training, group exercise leader, and pilates mat/equipment from the training organization Fitness Studio One, Certified Study Institute by Euro Active.",
    "image": doctor21
  }
]

  
export const greekDoctorDetails = [
  {
    name: 'Sabine Wünschmann',
    title: 'Χειρουργός Γυναικολόγος - Μαιευτήρας, με Εξειδίκευση στην Ορμονική Αποκατάσταση',
    description:'Αποφοίτησε το 1990 από την Ιατρική Σχολή του Πανεπιστημίου της Φρανκφούρτης. Κατά τη διάρκεια της φοίτησης της στην Ιατρική Σχολή έκανε πρακτική εξάσκηση στο Αμβούργο, στο Παρίσι, στην Αυστραλία, την Ελβετία, την Κίνα και την Ταϊβάν. Την περίοδο 1990-1997 πήρε την εξειδίκευση της στη Μαιευτική και Γυναικολογία από το νοσοκομείο της Φρανκφούρτης.',
    image: doctor1,
  },
  {
    name: 'Παρασκευή Μεντζελοπούλου',
    title: 'Ενδοκρινολόγος-Διαβητολόγος',
    description: 'H Παρασκευή Μεντζελοπούλου αποφοίτησε από την Ιατρική Σχολή του Αριστοτέλειου Πανεπιστήμιου Θεσσαλονίκης (1992-1998) και στη συνέχεια ολοκλήρωσε την θητεία της ως αγροτικός ιατρός στο Γενικό νοσοκομείο Αιγίου (1998-2000). To 2001 αναχώρησε για τις Ηνωμένες Πολιτείες Αμερικής για να συνεχίσει την εκπαίδευσή της όπου έλαβε την ειδίκευση της στην Παθολογία στο Yale University Internal Medicine Program.',
    image: doctor2,
  },
  {
    name: 'Μαρία Χαραμαντά',
    title: 'Μαιευτήρας-Γυναικολόγος',
    description:'Η Μαρία Χαραμαντά είναι Γυναικολόγος - Μαιευτήρας, απόφοιτη της Ιατρικής Σχολής του Αριστοτελείου Πανεπιστημίου Θεσσαλονίκης και κάτοχος μεταπτυχιακού διπλώματος στην Αναπαραγωγική και Αναγεννητική Ιατρική από το Εθνικό και Καποδιστριακό Πανεπιστήμιο Αθηνών',
    image: doctor3,
  },
  {
    name: 'Άννα Γαβριέλη',
    title: 'Διαιτολόγος-Διατροφολόγος',
    description:
      'Η Άννα Γαβριέλη είναι Διαιτολόγος-Διατροφολόγος. Τελείωσε το τμήμα Επιστήμης Διαιτολογίας – Διατροφής του Χαροκοπείου Πανεπιστημίου στην Αθήνα. Στη συνέχεια πραγματοποίησε το μεταπτυχιακό της πάνω στην αθλητική διατροφή στο Πανεπιστήμιο του Loughborough του Ηνωμένου Βασιλείου.',
    image: doctor4,
  },
  {
    name: 'Ηλιάνα Καραγκούνη',
    title: 'Κλινικός Διαιτολόγος',
    description:
      'Η Ηλιάνα Καραγκούνη είναι πτυχιούχος του τμήματος Νοσηλευτικής του Εθνικού και Καποδιστριακού Πανεπιστημίου Αθηνών και κάτοχος μεταπτυχιακού διπλώματος ειδίκευσης στην «Οργάνωση και Διοίκηση Υπηρεσιών Υγείας». Παράλληλα, είναι πτυχιούχος του Τμήματος Διαιτολογίας του Πανεπιστημίου Queen Margaret University of Edinburgh και απόφοιτος του Τμήματος Επιστήμης Διαιτολογίας-Διατροφής του Χαροκοπείου Πανεπιστημίου.',
    image: doctor5,
  },
  {
    name: 'Αλίνα Τσατούροβα',
    title: 'Ψυχολόγος',
    description:
      "Η Αλίνα Τσατούροβα είναι Ψυχολόγος. Έχει εξειδικευτεί στην Κλινική Υπνοθεραπεία και Γνωστική Συμβουλευτική, καθώς και τον Νευρογλωσσικό προγραμματισμό. Από το 2018 έως το 2021, σπούδασε στο University of East London. Το 2021-2022 απέκτησε Professional Diploma in Hypnotherapy, ενώ το 2022-2023 ολοκλήρωσε το Advanced Professional Diploma in Hypnotherapy with Psychotherapy στη Soleas Academy.",
    image: doctor6
  },
  {
    name: 'Φωτεινή Φερενίδου',
    title: 'Ψυχίατρος και Διδάκτωρ Ιατρικής του Αριστοτελείου Πανεπιστημίου',
    description:
      'H Φωτεινή Φερενίδου, MD, MSc, FECSM, είναι Ψυχίατρος και Διδάκτωρ Ιατρικής του Αριστοτελείου Πανεπιστημίου Θεσσαλονίκης. Αποφοίτησε από την Ιατρική Σχολή του Αριστοτελείου Πανεπιστημίου Θεσσαλονίκης και συνέχισε τις σπουδές της με το Μεταπτυχιακό Δίπλωμα Εξειδίκευσης στην «Ιατρική Ερευνητική Μεθοδολογία-Κλινική έρευνα» (MSc) της Ιατρικής Σχολής του Α.Π.Θ.',
    image: doctor7,
  },
  {
    name: 'Ειρήνη Μηνοπούλου',
    title: 'Κλινική Ψυχολόγος - Ψυχοθεραπεύτρια, MSc, με ειδίκευση στη Θεραπεία Ζεύγους',
    description:
      'Η Ειρήνη Μηνοπούλου είναι κλινική ψυχολόγος-ψυχοθεραπεύτρια, MSc με ειδίκευση στη θεραπεία ζεύγους - οικογένειας και στην κλινική σεξολογία. Είναι επιστημονική συνεργάτης στο Ειδικό Ιατρείο Σεξουαλικής Υγείας & Ασυμφωνίας Φύλου, στο Αιγινήτειο Νοσοκομείο και στην A Ψυχιατρική κλινική του Πανεπιστημίου Αθηνών. Επίσης είναι μέλος της Ελληνικής Εταιρείας Συστημικής Σκέψης και Ψυχοθεραπείας Οικογένειας',
    image: doctor8,
  },
  {
    name: 'Ηρακλής Μουρίκης',
    title: 'Ψυχίατρος',
    description:
      'Ο Ηρακλής Μουρίκης είναι Ψυχίατρος, Ψυχοθεραπευτής, με κλινικό και ερευνητικό έργο στην A’ Ψυχιατρική κλινική του Πανεπιστημίου Αθηνών, στο Αιγινήτειο Νοσοκομείο. Είναι επικεφαλής της Μονάδας Θεραπείας Συμπεριφοράς, του Ειδικού Ιατρείου για την Ιδεοψυχαναγκαστική Διαταραχή και του Ειδικού Ιατρείου Σεξουαλικής Υγείας.',
    image: doctor9,
  },
  {
    name: 'Βιβή Σωτηροπούλου',
    title: 'Ψυχολόγος, Κλινική Σεξολόγος',
    description:
      'Η Βιβή Σωτηροπούλου είναι ψυχολόγος, διδάκτωρ ψυχολογίας (Πάντειο Πανεπιστήμιο), ειδικευμένη στη θεραπευτική αντιμετώπιση αγχωδών και σεξουαλικών διαταραχών. Είναι επιστημονική συνεργάτις της Α’ Ψυχιατρικής Κλινικής Πανεπιστημίου Αθηνών, Αιγινήτειο Νοσοκομείο.',
    image: doctor10,
  },
  {
    name: 'Μαρία Μπάστα',
    title: 'Ψυχίατρος, Αναπληρώτρια Καθηγήτρια Ψυχιατρικής',
    description:
      'Η πορεία της Δρ. Μαρίας Μπάστα στον τομέα της ψυχιατρικής ξεκίνησε όταν αποφοίτησε από την Ιατρική Σχολή του Πανεπιστημίου Κρήτης το 1997. Το πάθος της για την ψυχιατρική την οδήγησε να ολοκληρώσει την ειδικότητά της στο Τμήμα Ψυχιατρικής του Πανεπιστημιακού Νοσοκομείου Ηρακλείου το 2006.',
    image: doctor11,
  },
  {
    name: 'Μάντυ Μουζακίτη',
    title: 'Ψυχολόγος',
    description:
      'Η Μάντυ Μουζακίτη σπούδασε Ψυχολογία στο Πανεπιστήμιο Κρήτης. Έχει εκπαιδευτεί στη γνωσιακή – συμπεριφοριστική ψυχοθεραπεία στην Εταιρεία Γνωσιακών Συμπεριφοριστικών Σπουδών σύμφωνα με τα κριτήρια της European Association for Behavioral and Cognitive Therapies (EABCT)6.',
    image: doctor12,
  },
  {
    name: 'Σοφία Κατούδη',
    title: 'Ψυχολόγος-Ψυχοθεραπευτής',
    description:
      'Η Σοφία Κατούδη είναι ψυχολόγος, απόφοιτη του τμήματος Ψυχολογίας του Παντείου Πανεπιστημίου. Έχει εκπαιδευτεί στη Γνωσιακή Συμπεριφοριστική Θεραπεία (ΓΣΘ) στην Εταιρεία Γνωσιακών Συμπεριφοριστικών Σπουδών και έχει ολοκληρώσει διετές πρόγραμμα εκπαίδευσης στη Γνωσιακή Θεραπεία βασισμένη στην Ενσυνειδητότητα (MBCT) που διοργανώνεται από το Oxford Mindfulness Foundation σε συνεργασία με την ΕΓΣΣ',
    image: doctor13,
  },
  {
    name: 'Μελίνα Νόνη',
    title: 'Ψυχολόγος',
    description:
      'Η Νόνη Μελίνα είναι Ψυχολόγος - Ψυχοθεραπεύτρια και διατηρεί ιδιωτικό γραφείο στο Μαρούσι. Έχει ειδικευτεί στη Γνωσιακή Συμπεριφοριστική Θεραπεία παιδιών- εφήβων και ενηλίκων στο Ινστιτούτο Έρευνας και Θεραπείας της Συμπεριφοράς.',
    image: doctor14,
  },
  {
    name: 'Φωτεινή Λέκκα',
    title: 'Ψυχολόγος',
    description:
      'Η Φωτεινή Λέκκα είναι Ψυχολόγος, έχει ειδικευθεί στην Κλινική Ψυχολογία σε μεταπτυχιακό επίπεδο και είναι Διδάκτωρ Κλινικής Ψυχολογίας. Είναι Διδάσκουσα και Επόπτρια Γνωσιακής – Συμπεριφοριστικής Ψυχοθεραπείας στην Εταιρεία Γνωσιακών – Συμπεριφοριστικών Σπουδών',
    image: doctor16,
  },
  {
    name: 'Γιάννης Ζέρβας',
    title: 'Καθηγητής Ψυχιατρικής, Ιατρική Σχολή, ΕΚΠΑ',
    description:
      'Ο Γιάννης Ζέρβας είναι Καθηγητής Ψυχιατρικής στην Ιατρική Σχολή του Εθνικού και Καποδιστριακού Πανεπιστημίου Αθηνών. Έχει λάβει το MD και το διδακτορικό του από το Πανεπιστήμιο Αθηνών και ειδικεύτηκε στην Ψυχιατρική στο Stony Brook University Hospital στη Νέα Υόρκη, όπου στην συνέχεια εξειδικεύτηκε στην Συμβουλευτική-Διασυνδετική Ψυχιατρική και Ψυχοσωματική Ιατρική και στην Ψυχοθεραπεία. Είναι υπεύθυνος της Μονάδας Ψυχικής υγείας της Γυναίκας στο Αιγινήτειο Νοσοκομείο ',
    image: doctor17,
  },
  {
    name: 'Βίκυ Κουτά',
    title: 'Ψυχολόγος',
    description:
      'Η Βίκυ Κουτά είναι Ψυχολόγος-Ψυχοθεραπεύτρια, Γνωσιακής-Συμπεριφορικής Θεραπείας (CBT). Είναι απόφοιτη του Εθνικού και Καποδιστριακού Πανεπιστημίου Αθηνών, του τμήματος Φιλοσοφίας-Παιδαγωγικής και Ψυχολογίας. Ολοκλήρωσε τις προπτυχιακές της σπουδές στο τμήμα Ψυχολογίας του Πανεπιστημίου της Λευκωσίας. Στην συνέχεια, μετέβη στην Σκωτία, όπου ολοκλήρωσε τις μεταπτυχιακές της σπουδές στην Ψυχική Υγεία Παιδιού και Εφήβου, στο Πανεπιστήμιο του Εδιμβούργου. Τα τελευταία χρόνια διατηρεί ιδιωτικό γραφείο στο Μαρούσι.',
    image: doctor18,
  },
  {
    name: 'Χρύσα Βαφειάδη',
    title: 'Φυσιοθεραπευτρια, Msc στο Manual Therapy και την Υγεία των Γυναικών',
    description:
      'Η Χρύσα Βαφειάδη κατέχει Bsc Φυσιοθεραπείας, Msc στο Manual Therapy και την Υγεία των Γυναικών. Αφού ολοκλήρωσε το πτυχίο της στη φυσιοθεραπεία, πήγε στο Ηνωμένο Βασίλειο στα Πανεπιστήμια του Σέφιλντ και Μπράντφορντ για να ολοκληρώσει το διπλό μεταπτυχιακό της στο Manual Therapy και στη φυσικοθεραπεία. Έχει εξειδίκευση στη φυσιοθεραπεία υγείας των γυναικών και στη φυσικοθεραπεία MSK (Μυοσκελετική)',
    image: doctor19,
  },
  {
    name: 'Νίκος Λαγός',
    title: 'Φυσιοθεραπευτής',
    description:
      'Γεννημένος στη Αθήνα, έκανε τα πρώτα του βήματα στον αθλητισμό από μικρή ηλικία και ακολούθησε ο πρωταθλητισμός στον στίβο εφήβων και ανδρών. Διακρίθηκε πολλές φορές ως Πανελληνιονίκης στο Άλμα εις τριπλούν σε όλες τις κατηγορίες του αθλήματος, όπως επίσης σε Παγκόσμια και Πανευρωπαϊκά πρωταθλήματα. Σπούδασε φυσικοθεραπευτής στο University of East London',
    image: doctor20,
  },
  {
    name: 'Γιώργος Κιτσαντάς',
    title: 'Strength Coach-Φυσιοθεραπευτής',
    description:
      'O Κιτσαντάς Γιώργος είναι Φυσιοθεραπευτής και Strength Coach. Μετά την επαγγελματική του ενασχόληση με το άθλημα της πετοσφαίρισης, ξεκίνησε την εκπαίδευσή του σε ιδιωτική σχολή fitness, αποκτώντας τα διπλώματα personal training, group exercise leader, και pilates mat/equipment από τον εκπαιδευτικό οργανισμό Fitness Studio One, Πιστοποιημένο Ινστιτούτο Σπουδών από το Euro Active',
    image: doctor21,
  },
];

  


export const listingTypes = [
  // {
  //   listingType: 'daily-booking',
  //   label: 'Daily booking',
  //   transactionType: {
  //     process: 'default-booking',
  //     alias: 'default-booking/release-1',
  //     unitType: 'day',
  //   },
  // },
  // // Here are some examples for other listingTypes
  // // TODO: SearchPage does not work well if both booking and product selling are used at the same time
  {
    listingType: 'instant-booking',
    label: 'Instant booking',
    transactionType: {
      process: 'instant-booking',
      alias: 'instant-booking/release-1',
      unitType: 'hour',
    },
  },
  {
    listingType: 'hourly-booking',
    label: 'Hourly booking',
    transactionType: {
      process: 'default-booking',
      alias: 'default-booking/release-1',
      unitType: 'hour',
    },
  },
  // {
  //   listingType: 'product-selling',
  //   label: 'Sell bicycles',
  //   transactionType: {
  //     process: 'default-purchase',
  //     alias: 'default-purchase/release-1',
  //     unitType: 'item',
  //   },
  //   stockType: 'multipleItems',
  // },
];

// SearchPage can enforce listing query to only those listings with valid listingType
// However, it only works if you have set 'enum' type search schema for the public data fields
//   - listingType
//
//  Similar setup could be expanded to 2 other extended data fields:
//   - transactionProcessAlias
//   - unitType
//
// Read More:
// https://www.sharetribe.com/docs/how-to/manage-search-schemas-with-flex-cli/#adding-listing-search-schemas
export const enforceValidListingType = false;
